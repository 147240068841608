import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 452.000000 666.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g viewBox="0 0 980.000000 980.000000" transform="translate(0.000000,666.000000) scale(0.100000,-0.100000)">
 
<path d="M1878 5012 c-17 -2 -28 -9 -28 -18 0 -9 5 -12 13 -8 6 4 4 -1 -5 -12
-12 -13 -19 -43 -22 -93 -2 -40 -7 -75 -10 -79 -3 -3 -6 -20 -6 -39 l0 -33
276 0 275 1 -6 42 c-4 23 -12 85 -18 137 l-12 95 -45 7 c-25 3 -113 7 -197 7
-122 1 -154 -2 -167 -14 -11 -11 -15 -12 -18 -2 -2 7 -15 11 -30 9z m459 -184
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M1510 4926 c-48 -19 -56 -26 -65 -61 -6 -22 -11 -61 -11 -87 1 -25
-2 -44 -5 -42 -4 2 -9 -8 -12 -22 -5 -26 -4 -26 41 -20 24 3 55 6 67 6 20 0
24 9 44 114 11 63 21 119 21 125 0 15 -16 13 -80 -13z m37 -78 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-10 -60 c-3 -7 -5 -2 -5 12 0 14 2
19 5 13 2 -7 2 -19 0 -25z m3 -58 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
10 11 10 2 0 4 -4 4 -10z"/>
<path d="M2600 4919 c6 -18 15 -72 21 -119 6 -47 14 -88 18 -91 3 -4 35 -10
70 -13 l64 -7 -8 53 c-4 29 -8 61 -9 70 -1 24 -16 11 -16 -14 0 -12 -3 -18 -7
-15 -3 4 -1 24 7 46 14 45 1 81 -30 81 -10 0 -22 5 -26 11 -3 6 -17 9 -30 6
-13 -2 -21 0 -18 4 3 5 1 9 -5 9 -5 0 -16 3 -25 6 -13 5 -14 1 -6 -27z m160
-185 c0 -2 -7 -4 -15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z"/>
<path d="M1154 4745 c-4 -8 -10 -12 -15 -9 -5 3 -9 0 -9 -5 0 -6 -4 -11 -10
-11 -5 0 -27 -12 -49 -27 -35 -26 -40 -35 -54 -103 -28 -136 -30 -158 -14
-175 13 -13 16 -13 21 0 3 8 0 14 -6 12 -7 -1 -14 6 -16 17 -2 11 0 16 6 12 6
-3 12 4 14 16 4 30 26 34 30 6 2 -14 10 -23 20 -23 25 0 23 -19 -2 -25 -12 -3
-22 -9 -23 -15 -1 -5 -5 -9 -9 -7 -5 1 -8 -7 -8 -19 0 -14 -5 -19 -14 -16 -8
3 -13 -1 -12 -11 0 -9 -10 -22 -24 -29 -14 -7 -19 -13 -12 -13 7 0 11 -4 8 -9
-4 -5 0 -12 6 -14 9 -4 8 -6 -2 -6 -23 -1 -96 75 -78 82 23 9 -26 7 -88 -3
-62 -11 -118 -30 -110 -37 2 -2 -9 -18 -25 -36 -16 -17 -27 -33 -25 -35 3 -2
-5 -13 -17 -25 -30 -30 -12 -32 98 -8 50 11 82 20 71 20 -16 1 -16 3 -5 17 7
8 21 13 31 11 13 -4 18 1 18 17 0 26 17 46 40 46 18 0 45 -54 34 -71 -4 -8 2
-9 22 -6 16 3 49 9 74 12 74 11 79 14 85 53 3 20 10 62 15 92 17 97 23 144 24
198 1 28 4 52 9 52 4 0 7 15 7 33 0 18 5 38 12 45 9 9 9 12 0 12 -7 0 -15 -7
-18 -15z m-87 -397 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-240 6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-120 -86 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M3016 4747 c3 -8 11 -14 19 -14 20 0 19 4 -5 17 -15 8 -18 8 -14 -3z"/>
<path d="M3068 4713 c16 -6 15 -18 -4 -38 -13 -12 -14 -19 -4 -30 10 -12 9
-16 -4 -21 -18 -7 -22 -44 -5 -44 5 0 9 -12 7 -27 -1 -16 4 -81 11 -146 12
-106 15 -118 35 -122 17 -3 211 -34 321 -50 17 -2 56 -11 88 -18 31 -8 57 -12
57 -10 0 2 -23 35 -52 74 -32 43 -57 68 -66 64 -7 -2 -11 -1 -8 4 5 8 -15 12
-124 26 -32 4 -39 3 -25 -4 17 -8 17 -10 3 -10 -9 -1 -21 5 -25 13 -4 8 -21
16 -38 18 -29 3 -30 4 -46 98 -29 173 -26 166 -71 199 -23 17 -47 31 -52 30
-6 0 -5 -3 2 -6z m29 -205 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m20 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m52 -28 c0
-10 -3 -8 -9 5 -12 27 -12 43 0 25 5 -8 9 -22 9 -30z m-32 -22 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-27 -45 c-11 -17 -11 -17 -6 0 3 10
6 24 7 30 0 9 2 9 5 0 3 -7 0 -20 -6 -30z m130 -53 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-126 -16 c3 -8 2 -12 -4 -9 -6 3
-10 10 -10 16 0 14 7 11 14 -7z"/>
<path d="M3031 4693 c0 -11 3 -13 6 -5 2 6 10 9 15 6 7 -4 8 -2 4 4 -11 18
-26 14 -25 -5z"/>
<path d="M1648 4662 l-48 -3 0 -49 c0 -26 -3 -56 -6 -65 -6 -15 1 -16 67 -9
41 3 75 7 75 8 1 0 3 28 5 61 4 68 11 64 -93 57z m54 -64 c3 -27 2 -48 -3 -48
-5 0 -9 23 -9 52 0 62 5 60 12 -4z m21 -8 c3 -11 1 -18 -4 -14 -5 3 -9 12 -9
20 0 20 7 17 13 -6z"/>
<path d="M2445 4606 c3 -33 7 -61 8 -62 1 -1 35 -5 76 -8 l74 -7 -6 38 c-4 21
-7 52 -7 68 0 21 -3 26 -9 16 -5 -8 -10 -9 -12 -3 -2 6 -33 13 -68 15 l-63 5
7 -62z m42 -42 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M1788 4478 c0 -7 -2 -39 -4 -72 l-2 -58 132 6 c189 9 257 9 384 0
l113 -7 -5 44 c-2 24 -5 56 -5 71 l-1 26 -305 1 c-241 1 -305 -1 -307 -11z
m79 -14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m23 -32 c3 -2
-10 -3 -28 0 -25 4 -32 1 -30 -11 2 -9 -5 -16 -17 -18 -11 -1 -14 0 -7 3 6 3
12 14 12 25 0 16 5 18 33 12 17 -3 34 -8 37 -11z m158 -49 c-21 -2 -55 -2 -75
0 -21 2 -4 4 37 4 41 0 58 -2 38 -4z"/>
<path d="M1443 4461 c-28 -4 -54 -9 -58 -12 -6 -7 -28 -130 -23 -134 6 -5 129
13 136 20 4 4 7 29 7 56 0 27 3 49 8 49 4 0 7 7 7 15 0 8 -6 14 -12 14 -7 -1
-36 -5 -65 -8z m-13 -30 c0 -5 -9 -7 -20 -4 -11 3 -20 7 -20 9 0 2 9 4 20 4
11 0 20 -4 20 -9z m-13 -83 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z"/>
<path d="M2670 4448 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10
-22z"/>
<path d="M2701 4448 c0 -9 -4 -25 -7 -35 -5 -14 -4 -15 5 -7 7 7 11 22 8 35
-2 13 -5 16 -6 7z"/>
<path d="M2720 4452 c0 -5 11 -15 24 -22 21 -11 24 -18 19 -44 -3 -17 -15 -39
-27 -50 -21 -18 -21 -18 26 -15 25 2 44 1 41 -3 -2 -5 3 -8 12 -8 12 0 15 5
11 16 -3 9 -6 34 -6 55 0 22 -4 38 -9 35 -5 -4 -7 2 -4 13 3 13 -2 20 -19 24
-33 9 -68 8 -68 -1z"/>
<path d="M930 4380 c-11 -7 -11 -10 -2 -10 7 0 11 -4 8 -9 -8 -12 18 -21 33
-11 10 5 10 9 2 14 -6 4 -9 12 -6 17 8 12 -16 11 -35 -1z"/>
<path d="M2680 4366 c0 -14 4 -27 8 -30 5 -3 6 8 4 25 -5 35 -12 38 -12 5z"/>
<path d="M874 4309 c-3 -6 0 -15 7 -20 29 -18 39 -7 15 17 -11 10 -17 11 -22
3z"/>
<path d="M848 4253 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1603 4061 c-144 -4 -265 -10 -269 -14 -15 -15 -27 -797 -18 -1159 5
-196 12 -360 16 -364 22 -21 1504 -22 1525 0 14 14 24 758 14 1130 -6 214 -13
391 -15 394 -7 7 -620 21 -831 20 -88 -1 -278 -4 -422 -7z m685 -292 c49 -16
72 -32 111 -75 65 -70 86 -132 85 -246 -1 -108 -30 -175 -105 -242 -76 -68
-115 -78 -321 -84 l-178 -4 -2 -161 -3 -162 -87 -3 -88 -3 0 501 0 500 263 0
c240 0 268 -2 325 -21z"/>
<path d="M1880 3455 l0 -197 148 4 c163 6 202 16 244 67 31 36 44 100 34 168
-8 57 -29 93 -74 123 -33 24 -45 25 -194 28 l-158 4 0 -197z"/>
<path d="M1003 4020 c-29 -3 -57 -10 -63 -16 -6 -6 -13 -41 -17 -77 l-6 -68
59 7 c32 4 63 10 67 14 11 10 27 133 18 140 -3 3 -30 3 -58 0z"/>
<path d="M3126 3956 c4 -39 8 -73 10 -75 6 -6 127 -22 131 -17 3 2 2 34 -2 71
-8 75 -14 80 -92 87 l-53 5 6 -71z"/>
<path d="M561 3951 l-84 -16 -23 -70 c-12 -38 -22 -70 -21 -71 1 -1 29 3 62 8
33 5 85 11 115 14 30 2 58 8 62 12 5 5 11 38 15 75 7 78 14 76 -126 48z"/>
<path d="M3503 3904 c3 -38 6 -70 6 -71 2 -3 106 -22 151 -27 25 -3 56 -8 69
-10 22 -5 23 -3 16 31 -3 20 -13 53 -21 73 -14 35 -17 37 -92 49 -42 7 -90 15
-106 18 l-28 6 5 -69z"/>
<path d="M788 3787 c-63 -10 -64 -12 -70 -107 l-3 -45 50 3 c71 5 88 11 82 31
-2 10 -1 40 3 67 5 27 5 52 2 55 -4 3 -33 1 -64 -4z"/>
<path d="M3337 3749 c9 -110 7 -107 75 -111 l61 -3 -6 69 c-3 37 -8 70 -10 72
-2 2 -30 7 -63 10 l-60 6 3 -43z"/>
<path d="M1020 3607 c-3 -3 -27 -6 -55 -8 -27 -1 -56 -4 -62 -7 -10 -3 -13
-74 -13 -307 l0 -303 68 -4 c37 -3 68 -4 70 -3 1 1 1 145 0 319 -2 175 -5 315
-8 313z"/>
<path d="M3160 3286 l0 -315 58 6 c31 2 63 6 70 9 9 2 12 72 12 302 l0 300
-31 6 c-17 3 -49 6 -70 6 l-39 0 0 -314z"/>
<path d="M650 3567 c-5 -4 -31 -7 -201 -23 l-77 -7 -6 -43 c-15 -109 -17 -238
-7 -338 6 -60 12 -111 14 -112 6 -7 277 -37 282 -32 7 6 2 561 -5 555z"/>
<path d="M3528 3292 c-2 -210 0 -276 9 -279 13 -5 275 26 282 33 9 9 12 381 4
434 l-8 55 -142 15 -142 16 -3 -274z"/>
<path d="M3407 2939 c-32 -4 -59 -8 -61 -10 -2 -2 -6 -37 -9 -77 l-6 -73 47 6
c91 13 96 19 90 111 l-3 49 -58 -6z"/>
<path d="M713 2925 c4 -9 7 -41 7 -70 0 -60 -1 -59 88 -70 l52 -7 -6 74 c-4
40 -9 75 -11 78 -2 2 -34 6 -70 8 -59 4 -65 3 -60 -13z"/>
<path d="M435 2759 c4 -18 16 -52 26 -75 19 -45 25 -48 164 -70 22 -3 48 -8
58 -11 17 -5 18 0 12 64 -4 37 -10 71 -14 75 -3 4 -53 14 -111 23 -58 8 -113
18 -124 21 -16 5 -17 2 -11 -27z"/>
<path d="M3630 2768 c-63 -10 -118 -22 -121 -26 -4 -4 -9 -37 -12 -75 -6 -65
-6 -67 16 -62 12 2 60 11 105 20 46 8 88 17 93 20 4 3 18 35 29 71 23 75 23
74 13 73 -5 -1 -60 -10 -123 -21z"/>
<path d="M920 2666 c0 -91 8 -102 81 -110 34 -3 64 -4 66 -2 2 1 0 35 -3 75
-8 79 -2 74 -96 86 l-48 6 0 -55z"/>
<path d="M3188 2711 c-26 -4 -49 -9 -51 -11 -3 -3 -8 -38 -12 -78 l-7 -74 48
7 c94 12 92 11 99 63 3 26 8 59 10 75 5 30 3 30 -87 18z"/>
<path d="M615 2369 c27 -46 98 -140 113 -148 9 -5 67 -17 127 -26 61 -10 113
-21 118 -24 4 -4 17 -61 28 -127 12 -65 26 -125 32 -132 14 -17 138 -104 143
-99 3 3 -57 388 -72 465 -3 16 -11 22 -29 22 -32 0 -425 67 -450 76 -14 6 -16
4 -10 -7z"/>
<path d="M3500 2363 c-36 -7 -144 -26 -240 -41 l-175 -28 -8 -54 c-4 -30 -22
-137 -38 -238 -17 -101 -29 -185 -27 -187 7 -8 129 77 143 99 7 11 21 71 30
131 10 61 23 115 29 121 6 6 61 19 121 29 61 10 117 22 127 26 13 7 77 91 111
147 9 14 11 14 -73 -5z"/>
<path d="M1363 2204 c4 -37 10 -71 14 -75 5 -4 38 -10 76 -14 76 -8 74 -11 61
88 -7 51 1 47 -113 62 l-44 6 6 -67z"/>
<path d="M2780 2263 c-99 -10 -98 -9 -105 -66 -3 -29 -9 -60 -12 -71 -5 -17 0
-18 68 -11 41 4 77 10 80 14 10 10 22 141 12 139 -4 0 -24 -3 -43 -5z"/>
<path d="M1776 2178 c3 -29 7 -63 10 -75 l4 -23 305 0 c283 0 305 1 306 18 0
9 4 43 8 75 l7 57 -323 0 -322 0 5 -52z"/>
<path d="M1585 2014 c14 -87 16 -92 38 -98 12 -3 46 -6 76 -6 l54 0 -7 62 c-4
35 -9 64 -10 65 -1 2 -37 6 -80 10 l-77 6 6 -39z"/>
<path d="M2483 2043 c-34 -4 -35 -7 -46 -102 l-4 -33 73 4 c40 2 75 6 78 9 3
3 9 33 12 67 l7 62 -44 -2 c-24 0 -58 -3 -76 -5z"/>
<path d="M1410 1874 c0 -23 30 -197 35 -202 3 -3 144 -52 149 -52 2 0 -36 249
-38 257 -1 1 -34 5 -73 8 -67 7 -73 6 -73 -11z"/>
<path d="M2665 1883 l-31 -4 -21 -127 c-12 -70 -20 -128 -19 -129 4 -4 109 32
133 46 15 7 24 32 36 104 9 51 17 99 17 105 0 12 -46 14 -115 5z"/>
<path d="M1816 1820 c3 -19 11 -84 19 -143 7 -60 15 -111 18 -113 18 -18 466
-19 483 -1 4 4 28 176 36 255 l3 34 -282 1 -282 2 5 -35z"/>
<path d="M122 1233 c3 -125 5 -148 19 -151 9 -1 16 4 16 13 0 8 -5 15 -11 15
-6 0 -5 4 2 9 8 4 13 11 13 15 -1 3 -1 21 -1 40 l0 34 66 4 c55 3 70 8 85 27
20 25 25 76 9 86 -6 4 -8 11 -4 16 3 5 -10 16 -28 23 -25 11 -35 11 -40 3 -6
-9 -8 -9 -8 1 0 8 -19 12 -60 12 l-61 0 3 -147z m155 101 c25 -12 31 -19 21
-25 -11 -7 -11 -9 0 -9 9 0 7 -9 -8 -30 -19 -27 -27 -30 -76 -30 l-54 0 0 55
0 55 41 0 c23 0 57 -7 76 -16z"/>
<path d="M390 1237 c0 -79 3 -146 6 -150 3 -3 13 -3 21 0 11 4 14 21 12 66
l-3 61 65 -3 c72 -3 79 -6 84 -47 2 -16 4 -31 5 -34 1 -3 3 -14 4 -24 0 -10 9
-20 19 -22 13 -2 17 2 13 13 -3 8 -6 24 -6 34 0 13 -4 18 -12 13 -10 -6 -10
-4 0 7 15 17 16 33 0 23 -8 -5 -9 -2 -5 9 3 9 0 23 -8 31 -12 12 -12 17 6 37
20 23 22 50 4 50 -5 -1 -10 -7 -10 -13 0 -28 -38 -48 -92 -48 -29 0 -56 0 -59
0 -4 0 -7 24 -7 53 l0 52 60 3 c47 2 65 -1 78 -15 19 -19 45 -26 45 -13 0 4
-8 18 -18 30 -16 20 -30 23 -110 27 l-92 3 0 -143z"/>
<path d="M672 1233 c3 -124 5 -148 18 -148 13 0 15 24 18 148 2 143 2 147 -18
147 -20 0 -20 -4 -18 -147z"/>
<path d="M791 1354 c-21 -17 -31 -35 -31 -53 0 -46 39 -71 130 -86 41 -6 69
-64 44 -89 -7 -8 -11 -15 -9 -17 3 -4 -40 -4 -93 0 -14 0 -29 13 -40 31 -9 16
-23 30 -29 30 -16 0 -17 -12 -1 -28 7 -7 9 -12 4 -12 -4 0 -1 -7 8 -15 8 -9
17 -14 19 -13 2 2 11 -3 19 -9 27 -23 128 -13 128 13 0 8 5 13 11 11 16 -3 36
56 21 61 -6 2 -9 9 -6 15 6 17 -40 42 -106 58 -30 7 -57 12 -60 12 -10 -3 -10
48 0 68 8 13 21 19 48 19 56 0 78 -9 88 -36 6 -15 13 -21 21 -16 9 6 9 13 0
32 -27 55 -115 67 -166 24z"/>
<path d="M1082 1364 c-13 -6 -29 -17 -35 -24 -9 -10 -9 -11 0 -6 9 5 11 2 7
-8 -3 -9 -10 -14 -15 -11 -13 9 -31 -74 -23 -108 4 -18 7 -35 6 -39 -1 -5 3
-8 9 -8 5 0 8 -4 5 -9 -3 -4 3 -19 14 -31 11 -13 20 -19 20 -14 0 5 5 2 10 -7
6 -11 26 -16 65 -18 53 -1 61 3 56 33 -1 5 5 6 13 3 8 -3 20 1 26 9 7 8 9 14
4 14 -4 0 -3 5 4 12 16 16 15 38 -2 38 -18 0 -29 -30 -20 -56 4 -14 3 -15 -4
-5 -7 10 -11 10 -17 1 -14 -22 -56 -31 -91 -20 -48 16 -64 44 -64 113 0 91 27
126 96 127 22 0 44 -4 50 -10 6 -6 10 -3 11 10 3 31 -75 39 -125 14z"/>
<path d="M1296 1343 c26 -38 44 -59 29 -34 -5 10 -4 12 4 7 6 -4 9 -11 6 -16
-3 -5 7 -29 22 -52 15 -24 29 -54 30 -68 2 -14 3 -29 4 -35 1 -5 -1 -17 -5
-25 -3 -8 -2 -21 3 -29 17 -27 31 1 31 61 0 45 5 67 21 89 11 16 17 34 13 40
-4 7 -3 9 4 5 6 -4 22 12 38 39 28 48 28 60 1 49 -17 -6 -80 -110 -80 -131 0
-27 -30 4 -64 68 -29 53 -43 69 -60 69 l-22 0 25 -37z"/>
<path d="M1540 1362 c0 -10 12 -13 50 -10 l49 3 3 -134 c2 -97 7 -136 16 -139
9 -3 12 28 12 132 l0 135 47 3 c25 2 48 9 50 15 4 10 -23 12 -111 11 -91 -2
-116 -5 -116 -16z"/>
<path d="M1820 1235 c0 -105 3 -144 13 -147 6 -3 52 -4 101 -4 66 1 90 5 94
15 4 11 -14 13 -97 9 -57 -2 -96 -1 -89 2 8 3 13 24 13 59 l0 54 78 -2 c66 -2
77 0 77 14 0 13 -10 16 -57 15 -104 -3 -112 -2 -108 18 2 9 4 31 4 49 l1 32
82 3 c54 2 83 7 85 15 4 10 -20 13 -96 12 l-101 0 0 -144z"/>
<path d="M2090 1230 l0 -150 80 0 c73 0 86 4 78 27 -2 3 -30 5 -63 4 -33 -1
-67 3 -75 9 -10 6 -11 10 -2 10 9 0 12 32 12 125 0 104 -2 125 -15 125 -13 0
-15 -23 -15 -150z"/>
<path d="M2321 1228 l-2 -149 97 3 c73 2 99 7 102 17 3 10 0 12 -12 8 -9 -4
-16 -3 -16 1 0 4 -31 6 -70 4 l-70 -4 0 56 0 56 81 0 c60 0 80 3 77 13 -3 8
-28 12 -79 12 l-74 0 -5 45 c-3 25 -4 50 -3 55 2 6 36 9 84 7 64 -3 80 -1 77
10 -3 9 -30 13 -95 14 l-91 1 -1 -149z"/>
<path d="M2630 1369 c-43 -16 -72 -70 -73 -136 0 -62 25 -129 45 -117 7 4 8 3
4 -4 -20 -33 111 -43 151 -12 17 13 19 20 10 29 -9 10 -8 11 6 6 9 -4 17 -3
17 1 0 5 3 19 6 32 5 16 2 22 -9 22 -9 0 -19 -10 -23 -22 -3 -13 -14 -32 -23
-42 -13 -16 -13 -18 -1 -11 8 4 12 4 7 0 -7 -9 -36 -15 -69 -16 -25 0 -65 32
-78 63 -7 16 -10 51 -6 85 7 77 8 83 17 78 3 -3 12 2 19 10 16 19 79 19 106 0
12 -8 24 -12 27 -9 3 3 3 -3 0 -15 -4 -17 -1 -21 16 -21 21 0 21 1 6 30 -9 18
-21 28 -27 24 -7 -4 -8 -3 -4 4 16 26 -70 41 -124 21z"/>
<path d="M2917 1369 c-9 -6 -14 -15 -11 -21 4 -7 2 -8 -5 -4 -17 11 -40 -17
-58 -69 -25 -76 3 -146 77 -185 24 -13 85 -11 115 3 29 15 66 60 60 75 -3 7 0
10 5 7 14 -9 12 8 -2 24 -11 11 -10 12 0 7 20 -12 9 84 -14 119 -31 47 -121
71 -167 44z m119 -32 c20 -12 44 -74 44 -114 0 -83 -66 -133 -146 -112 -40 12
-61 49 -65 115 -3 42 23 101 46 111 8 3 13 8 11 12 -6 9 95 -2 110 -12z"/>
<path d="M3162 1232 c2 -105 6 -147 16 -150 9 -3 12 27 12 127 0 72 4 131 8
131 4 0 8 -8 8 -17 1 -10 18 -69 39 -132 26 -78 40 -109 46 -100 6 10 9 11 9
2 0 -39 20 1 55 107 21 66 42 120 46 120 5 0 8 -48 8 -107 -1 -138 -1 -133 14
-133 11 0 14 30 13 148 l-1 147 -23 0 c-21 0 -28 -11 -53 -85 -16 -46 -27 -90
-24 -98 2 -7 0 -10 -5 -7 -9 6 -14 -9 -13 -41 0 -6 -8 -16 -18 -22 -14 -9 -17
-9 -13 2 5 14 -27 121 -52 168 -8 15 -14 29 -13 30 0 2 -2 15 -6 29 -5 20 -13
27 -31 27 l-25 0 3 -146z"/>
<path d="M3672 1365 c-19 -8 -36 -24 -39 -35 -3 -11 -9 -20 -14 -20 -12 0 -22
-85 -15 -125 4 -21 21 -50 42 -70 30 -30 41 -35 83 -35 56 0 93 24 109 69 11
31 7 41 -16 41 -10 0 -13 -8 -9 -25 2 -14 2 -24 0 -21 -3 3 -15 -6 -27 -18
-17 -20 -29 -23 -65 -20 -26 3 -49 11 -57 22 -23 29 -36 92 -28 136 4 22 7 44
6 47 0 3 10 12 23 19 14 7 25 16 25 19 0 4 18 5 40 3 28 -3 48 -13 68 -33 44
-48 56 -37 21 20 -14 24 -23 31 -28 22 -6 -10 -10 -9 -14 3 -8 20 -60 20 -105
1z m138 -239 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z"/>
<path d="M3962 1369 c-14 -5 -20 -13 -16 -20 4 -7 3 -9 -3 -6 -27 17 -68 -75
-61 -136 5 -41 19 -67 62 -108 27 -26 108 -26 151 1 34 21 60 64 49 82 -4 7
-2 8 4 4 9 -5 12 7 12 39 0 28 -4 44 -10 40 -6 -3 -7 1 -4 10 7 19 -23 68 -37
60 -5 -4 -8 -1 -7 6 7 30 -87 50 -140 28z m126 -41 c46 -51 53 -146 12 -183
-11 -10 -17 -22 -14 -27 3 -4 2 -8 -3 -7 -4 1 -17 1 -28 0 -17 -1 -18 -3 -5
-12 20 -14 -21 -4 -72 18 -38 16 -69 69 -67 116 1 40 24 89 49 103 40 22 103
18 128 -8z"/>
<path d="M4210 1246 c0 -74 3 -141 6 -150 16 -41 24 -5 25 112 1 70 3 119 6
109 2 -9 8 -15 13 -12 4 3 6 -4 3 -15 -3 -12 0 -20 6 -20 7 0 10 -4 7 -9 -4
-5 6 -42 20 -83 14 -40 28 -79 30 -86 3 -9 9 -10 22 -3 22 12 87 194 76 212
-4 7 -3 9 4 5 6 -3 13 2 15 11 3 10 5 -37 6 -103 1 -98 4 -123 16 -128 13 -5
15 15 15 145 0 150 0 150 -22 147 -19 -3 -29 -20 -60 -111 -20 -59 -34 -115
-31 -124 4 -10 2 -14 -4 -10 -6 4 -13 1 -16 -5 -2 -7 -10 6 -17 29 -7 24 -17
40 -23 36 -6 -3 -7 -1 -3 5 4 7 -5 50 -20 97 -25 74 -31 85 -51 85 -23 0 -23
-1 -23 -134z"/>
<path d="M1214 1325 c2 -14 6 -28 9 -31 8 -8 27 5 27 17 0 6 -4 8 -9 5 -5 -3
-8 2 -7 11 0 9 -4 18 -11 20 -7 3 -10 -5 -9 -22z"/>
<path d="M3500 1105 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15 -11
0 -20 -7 -20 -15z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
